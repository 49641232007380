.CoverAccountSignUp {
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color: var(--bgcolorvar);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUpAccountDiv{
    height: 90%;
    width: 80%;
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:1px solid red ;
    overflow-y: auto;
    margin: -50px;
}

.SignUpAccountDiv div{
    padding: 5px;
    border: 1px solid darkgreen;
    width :70% ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.SignUpLabelClass{
    width:45% ;
    color : red ;
}

.SignUpInputClass{
    width:45% ;
    color : blue ;
    border: 5px solid green ;
}

.SignUpTermsPopUpDivHide{
    height: 50%;
    width: 50%;
    position:fixed;
    display: none;
}

.SignUpTermsPopUpDivShow{
    height: 50%;
    width: 50%;
    position:fixed;
    display: block;
}

.SignUpTermsP{
    margin: 0;

}

.SignUpTermsP:hover{
    background-color: blue;
}





@media screen and (width < 992px) {
    .SignUpAccountDiv {
        justify-content: flex-start;
    }

    .SignUpAccountDiv div{
        flex-direction: column;
    }

    .AccountLabel{
        width:90% ;
    }
    
    .AccountInput{
        width:90% ;
    }

}