.CoverAccountLogin {
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color: var(--bgcolorvar);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginAccountDiv{
    height: 90%;
    width: 80%;
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow-y: auto;
}

.LoginAccountDiv > div {
    width: 80%;
}


.LoginLabelClass{
    width:15% ;
    color : red ;
}

.LoginInputClass{
    width:50% ;

}


@media screen and (width < 992px) {
    .LoginLabelClass{
        width:100% ;

    } 

    .LoginInputClass{
        width:80% ;

    }
}