.CoverQuestionsArea{
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color: var(--bgcolorvar);
    display:grid;
    grid-template-rows: repeat(5,20%);
    justify-items: center;
    align-items: center;
}

.QuestionsAreaText{
    font-family: "AlfaSlabOne";
    background-color: var(--addresscolorvar);
    border: 3px solid var(--bordercolorvar );
    border-radius: 25%;
    font-size: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 80%;
    grid-row: 1/3;

}

.QuestionsAreaText p {
    margin: 0;
    text-wrap: nowrap;

}

.AddQuestionsButton {
    font-size: 2.5rem;
    padding: 0.35em 1.3em;
    background-color: var(--bgcolorvar);
    border: 8px solid var(--bordercolorvar );
    border-radius: 1em;
    color: #fff;
    font-weight: bolder;
    text-decoration: none;

}

.QuestionsDiv{
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-row: 4/7;
}


.QuestionAreaDiv{

    margin: 3%;
    width: 100%;
    /* min-width: 25%; */
    /* max-width: 35%; */
    font-size: 2.7vw;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items:center;
    height: 100%;
    color: #ffffff;
}

.QuestionDiv{
    width: 70%;
    background-color: var(--fourthcolorvar) !important;
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
}

.AnswerDiv{
    width: 60%;
    background-color: var(--fourthcolorvar) !important;
    border: 3px solid var(--bordercolorvar );
    border-top: none;
    border-radius: 3%;

}

.AddQuestionForm{
    position: absolute;
    height: 60vh;
    width: 70vw;
    background-color: var(--formcolorvar );
    top:15vh ;
    right: 15vw;
    display: none;
    font-size: 2.5vw;
}


.AddQuestionFormDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column ;
    align-items: center;
    justify-content: space-evenly;

}

.AddQuestionFormDiv div:nth-child(1){
    width: 100%;
}

.AddQuestionFormDiv div:nth-child(2){
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


}
.AddQuestionFormDiv div:nth-child(3){
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;


}



@media screen and (width < 992px) {
    .AddPostButton{
        font-size: 2rem;
        padding: 0.3em 1em;    
    }
}