.CoverEvents{
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color: var(--bgcolorvar);
    display:grid;
    grid-template-rows: repeat(3,33.33%);
    justify-items: center;
    align-items: center;
}


.EventsText{
    font-family: "AlfaSlabOne";
    background-color: var(--addresscolorvar );
    border: 3px solid var(--bordercolorvar );
    border-radius: 25%;
    font-size: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 80%;
    grid-row: 1/2;

}
.EventsText p {
    margin: 0;
    text-wrap: nowrap;

}



.EventsDiv{
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-row: 2/5;

}


.EventDiv{
    background-color: var(--fourthcolorvar) !important;
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
    margin: 3%;
    /* width: 40%; */
    /* min-width: 25%; */
    max-width: 27.3%;
    /* max-width: 35%; */
    font-size: 2.7vw;
}

.EventDiv a {
    text-decoration:none ;
}
.EventDiv a p{
    color: rgb(195, 189, 12);
    text-wrap: nowrap;
}




.TTTTT{
    width: 100%;
    padding: 10px;

}



@media screen and (width < 992px) {
    .EventDiv{
        background-color: var(--fourthcolorvar) !important;
        border: 3px solid var(--bordercolorvar );
        border-radius: 5%;
        margin: 50px;
        min-width: 80%;
        font-size: 3vw;
    }
    
    .TTTTT{
        width: 100%;
    }
}


@media screen and (width < 1500px) {
    .EventDiv{
        background-color: var(--fourthcolorvar) !important;
        border: 3px solid var(--bordercolorvar );
        border-radius: 5%;
        margin: 3%;
        max-width: 44%;
        font-size: 3vw;
    
    }
    

}