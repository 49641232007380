@media screen and (width < 992px) {

    .NavBarName{
        margin: 2px;
        padding: 1px 30px;
        font-size: 3vw !important; 
        font-family: "AlfaSlabOne";
        border: 5px solid black;
        border-radius: 20%;
        text-shadow: 10px 0 8px rgb(9, 20, 235);
        text-decoration:none !important;
    }

    .NavBarName p{
        margin: 0;
    }


.NavBarMain {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 40px);
    background-color:var(--secondarycolorvar) !important;
    color: var(--fontcolorvar) !important;
    font-size: 4vw !important; 
    font-family: "Anton";
    margin: 20px;

}

.NavElement{
    text-wrap: nowrap;
}

.NavElement:hover {
    background-color: var(--thirdcolorvar);
    transition: 0.5s;
    border: 1px solid black;
    border-radius: 10% / 50% ;
}


.NavElement[s='true']:not(:hover) {
    background-color: var(--thirdcolorvar);
    transition: 0.5s;
    flex-shrink: 0;
    border: 1px solid black;
    border-radius: 30px;
}

.Icons{
    height: 4vw;
    width: 4vw;
}

}

@media screen  and (width >=992px ) {

    .NavBarName{
        margin: 10px;
        padding: 1px 15px;
        font-family: "AlfaSlabOne";
        border: 5px solid black;
        border-radius: 20%;
        text-shadow: 10px 0 8px rgb(9, 20, 235);
        text-decoration:none !important;
    }

    .NavBarName p{
        font-size: 2.1vw !important; 
        margin: 0;
    }




.NavBarMain {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 40px);
    background-color:var(--secondarycolorvar) !important;
    color: var(--fontcolorvar) !important;
    font-size: 1.37vw !important; 
    font-family: "Anton";
    margin: 20px;
    height: 90px;

}


.FlexContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
}


.Icons{
    height: 2.1vw;
    width: 2.1vw;
}

.NavElement{
    text-wrap: nowrap;
}

.NavElement:hover {
    background-color: var(--thirdcolorvar);
    margin-bottom: 100px;
    padding-bottom: 110px;
    transition: 0.5s;
    border: 1px solid black;
    border-radius: 10% / 50% ;
    width: 9vw;
}


.NavElement[s='true']:not(:hover) {
    background-color: var(--thirdcolorvar) ;
    margin-bottom: 100px;
    padding-bottom: 110px;
    transition: 0.5s;  
    flex-shrink: 0;
    width: 11vw;
    border: 1px solid black;
    border-radius: 30px;
}



}

@media screen  and (width >=1200px ) {

    .NavBarName{
        margin: 9px;
        padding: 1px 15px;
        font-family: "AlfaSlabOne";
        border: 5px solid black;
        border-radius: 20%;
        text-shadow: 10px 0 8px rgb(9, 20, 235);
        text-decoration:none !important;
    }

    .NavBarName p{
        font-size: 2vw !important; 
        margin: 0;
    }




.NavBarMain {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 40px);
    background-color:var(--secondarycolorvar) !important;
    color: var(--fontcolorvar) !important;
    font-size: 1.45vw !important; 
    font-family: "Anton";
    margin: 20px;
    height: 90px;

}


.FlexContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
}


.Icons{
    height: 2.3vw;
    width: 2.3vw;
}

.NavElement{
    text-wrap: nowrap;
}

.NavElement:hover {
    background-color: var(--thirdcolorvar);
    margin-bottom: 100px;
    padding-bottom: 110px;
    transition: 0.5s;
    border: 1px solid black;
    border-radius: 10% / 50% ;
    width: 9.5vw;
}


.NavElement[s='true']:not(:hover) {
    background-color: var(--thirdcolorvar);
    margin-bottom: 100px;
    padding-bottom: 110px;
    transition: 0.5s;  
    flex-shrink: 0;
    width: 11.5vw;
    border: 1px solid black;
    border-radius: 30px;
}



}




@media screen  and (width >=1500px ) {

    .NavBarName{
        margin: 11px;
        padding: 1px 15px;
        font-family: "AlfaSlabOne";
        border: 5px solid black;
        border-radius: 20%;
        text-shadow: 10px 0 8px rgb(9, 20, 235);
        text-decoration:none !important;


    }

    .NavBarName p{
        font-size:1.85vw !important; 
        margin: 0;
    }


    .NavBarMain {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 40px);
    background-color:var(--secondarycolorvar) !important;
    color: var(--fontcolorvar) !important;
    font-size: 1.6vw !important; 
    font-family: "Anton";
    margin: 20px;
    height: 11vh;

    }


.FlexContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
}


.Icons{
    height: 2.3vw;
    width: 2.3vw;
}

.NavElement{
    text-wrap: nowrap;

}

.NavElement:hover {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;
    border: 1px solid black;
    border-radius: 10% / 50% ;
    width: 9.7vw;
}


.NavElement[s='true']:not(:hover) {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;  
    flex-shrink: 0;
    width: 11.7vw;
    border: 1px solid black;
    border-radius: 30px;
}



}

@media screen  and (width >=1750px ) {

    .NavBarName{
        margin: 13px;
        padding: 1px 15px;
        font-family: "AlfaSlabOne";
        border: 5px solid black;
        border-radius: 20%;
        text-shadow: 10px 0 8px rgb(9, 20, 235);
        text-decoration:none !important;


    }

    .NavBarName p{
        font-size:1.9vw !important; 
        margin: 0;
    }


    .NavBarMain {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 40px);
    background-color:var(--secondarycolorvar) !important;
    color: var(--fontcolorvar)!important;
    font-size: 1.7vw !important; 
    font-family: "Anton";
    margin: 20px;
    height: 11vh;

    }


.FlexContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
}


.Icons{
    height: 2.4vw;
    width: 2.4vw;
}

.NavElement{
    text-wrap: nowrap;

}

.NavElement:hover {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;
    border: 1px solid black;
    border-radius: 10% / 50% ;
    width: 10.5vw;
}


.NavElement[s='true']:not(:hover) {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;  
    flex-shrink: 0;
    width: 12.5vw;
    border: 1px solid black;
    border-radius: 30px;
}



}


@media screen  and (width >=2000px ) {

    .NavBarName{
        margin: 14px;
        padding: 1px 15px;
        font-family: "AlfaSlabOne";
        border: 5px solid black;
        border-radius: 20%;
        text-shadow: 10px 0 8px rgb(9, 20, 235);
        text-decoration:none !important;


    }

    .NavBarName p{
        font-size:1.95vw !important; 
        margin: 0;
    }


    .NavBarMain {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 40px);
    background-color:var(--secondarycolorvar) !important;
    color: var(--fontcolorvar) !important;
    font-size: 1.73vw !important; 
    font-family: "Anton";
    margin: 20px;
    height: 11vh;

    }


.FlexContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
}


.Icons{
    height: 2.45vw;
    width: 2.45vw;
}

.NavElement{
    text-wrap: nowrap;

}

.NavElement:hover {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;
    border: 1px solid black;
    border-radius: 10% / 50% ;
    width: 10.6vw;
}


.NavElement[s='true']:not(:hover) {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;  
    flex-shrink: 0;
    width: 12.6vw;
    border: 1px solid black;
    border-radius: 30px;
}



}

@media screen  and (width >=2420px ) {

    .NavBarName{
        margin: 15px;
        padding: 1px 15px;
        font-family: "AlfaSlabOne";
        border: 5px solid black;
        border-radius: 20%;
        text-shadow: 10px 0 8px rgb(9, 20, 235);
        text-decoration:none !important;


    }

    .NavBarName p{
        font-size:2vw !important; 
        margin: 0;
    }


    .NavBarMain {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 40px);
    background-color:var(--secondarycolorvar) !important;
    color: var(--fontcolorvar) !important;
    font-size: 1.75vw !important; 
    font-family: "Anton";
    margin: 20px;
    height: 11vh;

    }


.FlexContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
}


.Icons{
    height: 2.5vw;
    width: 2.5vw;
}

.NavElement{
    text-wrap: nowrap;

}

.NavElement:hover {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;
    border: 1px solid black;
    border-radius: 10% / 50% ;
    width: 11vw;
}


.NavElement[s='true']:not(:hover) {
    background-color: var(--thirdcolorvar);
    margin-bottom: 10vh;
    padding-bottom: 11vh;
    transition: 0.5s;  
    flex-shrink: 0;
    width: 13vw;
    border: 1px solid black;
    border-radius: 30px;
}



}