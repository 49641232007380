.CoverMyCv{
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color:  var(--bgcolorvar);
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: repeat(5,20%);
    font-family: "SunsetBoulevard";

}

.PCVQA{
    grid-row: 1/3;
    font-size: 4vw;

}

.CVQ{
    background-color:  rgba(244, 242, 183, 0.78);
    cursor: context-menu;
    border: 10px solid var(--bordercolorvar );
    border-radius: 15%;
}


.CVQ p{
    margin: 0px;
}

.IconsDownArrow{
    height: 4vh;
    width: 4vw;
    transform: rotate(0);
}

.CVAc{
    background-color:  rgba(244, 242, 183, 0.78);
    border: 10px solid var(--bordercolorvar );
    border-radius: 15%;
}

#CVAid{
    display:none;
}

.CVAc p{
    margin: 0px;
}


.CVDownload{
    background-color: rgb(251, 231, 159);
    border: 10px solid var(--bordercolorvar );
    border-radius: 30%;
    grid-row: 3/5;
    font-size: 5.5vw;
    padding: 3%;
}

.CVDownload p {
    margin: 0px;
    text-shadow: 10px 0 8px rgb(195, 189, 12);

}

