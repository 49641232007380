
.CoverHome {
    min-height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--bgcolorvar);
}


.PersonalPhotoDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PersonalPhotoDivI {
    background-color: var(--picturecolorvar);
    width: 80vw;
    height: 50vh;
    border-radius: 80% 30%;
}

.PersonalPhotoImg {
    width: 75%;
    height: 75%;
    transform: rotate(-15deg);
}


.DescriptionDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

}

.DescriptionDiv p{
    color: rgb(195, 189, 12);
    font-size:1.25rem;
    font-family: "AlfaSlabOne";
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; 
}

.DescriptionDiv p p{
  display: inline;
  white-space:nowrap;
}

.DescriptionDiv Span{
    color: #FFFFFF;
}




