
.DetailsPostWithAdd{
    background-color:var(--secondarycolorvar);
    border-left: 8px solid rgb(0, 0, 0);
    height: 100%;
    display: grid;
    grid-template-rows: repeat(10,1fr);
    justify-items: center;
    align-items: center;
    width: 100%;
}

.DetailsPostWithAdd div:first-child {
    width: 100%;
    grid-row: 1/2;

}

.DetailsPostWithAdd div:nth-child(2) {
    width: 100%;
    grid-row: 2/3;
}
.DetailsPostWithAdd div:nth-child(3) {
    width: 100%;
    grid-row: 3/4;
}
.DetailsPostWithAdd div:nth-child(4) {
    width: 100%;
    grid-row: 4/6;
}
.DetailsPostWithAdd div:nth-child(5) {
    width: 100%;
    grid-row: 6/10;
    height: 100%;
}
.DetailsPostWithAdd div:nth-child(6) {
    width: 100%;
    height: 100%;
    grid-row: 10/11;
}

.DetailsPostWithAdd div:nth-child(5) textarea{
    height: 80%;
    width: 80%;
    font-size: large;
}






/* BUTTON  */

.PostButton {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #373B44;
    
    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
  
    border: 0;
  
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
  
.PostButton:hover,
.PostButton:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
    background-color: burlywood;
}
  
.PostButton:active {
    background: var(--color);
    color: #fff;
}



@media screen and (width < 992px) {

    .DetailsPostWithAdd{
        border-left:none;
        border-bottom: 8px solid rgb(0, 0, 0);
        height: 100%;
        padding-top: 25px ;
        padding-bottom: 25px;

    }
    
}
