.CoverAddPost{
    height: 80vh;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--bgcolorvar);
    display:flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    width: 100vw;
}

.AddPostDetails{
    width: 49%;
    height: 90%;
}

.AddPostView{
    width: 49%;
    height: 90%;

}


@media screen and (width < 992px) {

    .CoverAddPost{

        flex-direction: column-reverse;
        justify-content:flex-start;
        

    }

    .AddPostDetails{
        width: 100%;
        height: 100%;
        
    }
    
    .AddPostView{
        width: 100%;
        height: 100%;
        
    }




}



/* 

.ViewPostWithAdd{
    background-color: rgb(241, 140, 8);
    margin-top: 5%;
    padding-top: 3%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
    justify-items: center;
    align-items: center;
    width: 48%;
    height: inherit;
    border-right: 5px solid rgb(11, 197, 200);
}

.DetailsPostWithAdd{
    background-color: antiquewhite;
    margin-top: 5%;
    padding-top: 2%;
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:center;
    width: 48%;
    height: inherit;
    border-left: 5px solid rgb(11, 197, 200);

}


.PNameOfWriter{
    border: 3px solid black;
    padding: 10px;
    background-color: bisque;
    font-size: 100%;
    font-weight: bold;
    grid-row: 1/2;
    grid-column: 1/2;

}

.ViewPostImage{
    border: 3px solid black;
    grid-row: 1/2;
    grid-column: 2/3;
} */