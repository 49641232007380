body{
    --bgcolorvar : rgb(0, 0, 0) ;
    --secondarycolorvar : rgb(131,137,150)  ;
    --thirdcolorvar :rgba(137, 131, 200, 0.935) ;
    --bordercolorvar : rgb(131,137,150)  ;
    --fontcolorvar : rgb(0,0,0) ;
    --addresscolorvar :rgba(131,137,150, 0.587) ;
    --formcolorvar : rgba(137, 134, 151, 0.97) ;
    --fourthcolorvar :  rgba(241, 179, 7, 0.089) ;
    --picturecolorvar :rgb(131,137,150)  ;

}
/* body{
  --bgcolorvar : rgb(0, 0, 0) ;
  --secondarycolorvar : rgb(195, 189, 12)  ;
  --thirdcolorvar :rgba(252, 3, 3, 0.935) ;
  --bordercolorvar : rgb(195, 189, 12) ;
  --fontcolorvar : rgb(0,0,0) ;
  --addresscolorvar :rgba(249, 234, 192, 0.587) ;
  --formcolorvar : #00ffff ;
  --fourthcolorvar :  rgba(241, 179, 7, 0.089) ;
  --picturecolorvar :rgb(195, 189, 12) ;
  
} */
/*

silver color rgb(131,137,150) 
yallow color rgb(195, 189, 12) 
*/
.App {
  text-align: center;
  background-color: var(--bgcolorvar);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
