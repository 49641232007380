.CoverContact {
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color: var(--bgcolorvar);
    display:grid;
    grid-template-rows: repeat(3,33.33%);
    justify-items: center;
    align-items: center;
    width: 100vw;
}

.ContactText{
    font-family: "AlfaSlabOne";
    background-color: var(--addresscolorvar );
    border: 3px solid var(--bordercolorvar );
    border-radius: 25%;
    font-size: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 90%;
    grid-row: 1/2;
}

.ContactText p{
    margin: 0px;
    text-wrap: nowrap;
}

.ContactDiv{

    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-row: 2/5;
    /* height: 58vh; */
}


.ContactDivi{
    border: 3px solid var(--bordercolorvar) ;
    border-radius: 5% ;
    display: grid ;
    grid-template-rows: repeat(10,10%) ;
    width: 100% ;
    justify-items: center ;
    align-items: center ;
    font-size: 1.5rem ;
    height: 100% ;

}

/* .ContactDivi div:first-child{
    grid-row: 1/3;
} */

.ContactDis1{
    font-size: 2rem;
    color: aqua;
    margin-top: 50px;
    margin-bottom: 50px;
}




.ContactLinksDiv{
    grid-row: 1/11 ;
    margin-bottom: 50px;
    display:flex ;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    
}



.ContactLink{
    height: 100%;
    width: 23%;
    margin: 1%;
}

.ContactIcon{
display: block;
height: 80%;
width: 100%;
}

.ContactDis2{
    margin-top: 30px ;
    color : Red ;
    font-size: 2rem;
}

.QuestionsAreaButton {
  margin:10px ;
  font-size: 2.6rem;
  padding: 0.8em 1.3em;
  background-color: var(--bgcolorvar);
  border: 8px solid var(--bordercolorvar);
  border-radius: 1em;
  color: #fff;
  font-weight: bolder;
  text-decoration: none;
}

.ContactDis2 p{
    margin-bottom:50px ;
}


@media screen and (width < 566px) {
    .ContactLink{
        height: 90%;
        width: 40%;
        margin: 5%;

    }

    .ContactDis2{
        font-size: 1.5rem;
    }

    .QuestionsAreaButton {
        margin:5px ;
        font-size: 1.6rem;
        padding: 0.8em 0.8em;

    }

}

@media screen and (width < 330px) {
    .ContactLink{
        height: 90%;
        width: 90%;
        margin: 5%;

    }
    .ContactDis2{
        font-size: 1rem;
    }

    .QuestionsAreaButton {
        margin:3px ;
        font-size: 0.8rem;
        padding: 0.8em 0.5em;

    }

}