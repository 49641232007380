.ViewPostWithAdd{
    background-color:var(--secondarycolorvar);
    border-right: 8px solid rgb(0, 0, 0);
    /* border-right: 8px solid rgb(255, 0, 0); */
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
    justify-items: center;
    align-items: center;
    /* overflow-y: scroll; */
    overflow-x:hidden;
    /* scrollbar-width: thin;
    scrollbar-color: blue red; */

}

.PNameOfWriter{
    border: 3px solid black;
    padding: 10px;
    background-color: bisque;
    font-size: 100%;
    font-weight: bold;
    grid-row: 1/2;
    grid-column: 1/2;

}

.ViewPostImage{
    border: 3px solid black;
    grid-row: 1/2;
    grid-column: 2/3;
    width: 80%;
    height: 80%;
}

.ViewTextPost{
    border: 3px solid black;
    grid-row: 2/3;
    grid-column: 1/3;
    width: 90%;
    height: 90%;
    padding: 2%;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;

}


/* 



.ViewPostWithAdd{

    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
    justify-items: center;
    align-items: center;
    width: 48%;
    height: inherit;
}
*/



@media screen and (width < 992px) {

.ViewPostWithAdd{
    border-right:none;
    border-top: 8px solid rgb(0, 0, 0);
    height: 100%;
    padding-top: 25px ;
    padding-bottom: 25px;
}

}