.CoverAbout {
    /* min-height: 80vh; */
    min-height: 79vh;
    max-height: 80vh;
    display: flex;
    width: 100%;
    flex-direction:column;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: black;
}


.Pdiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
}


.DivA{
    display: flex;
    flex-direction: column;
    flex :1 ;
    width: 100%;
}
.DivA > div { 
    flex:1;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ShDivSp {
    flex:1 ;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    min-height: 300px;
}

.DivA1{
    background-color: var(--addresscolorvar);
    border-right: 1px solid black;

}

.ImgAbout{
    height: 55%;
    width: 55%;
    transform:scaleX(-1) rotate(-30deg);
    margin-bottom: 10%;
}

.DivA2{
    background-color: var(--addresscolorvar);
    border-bottom: 1px solid black;

}

.DivB{
    flex :1 ;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: BebasNeue;
}

.DivB > div {
    flex:1 ;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.PPAboutDiv{
    background-color: var(--picturecolorvar);
    width: 75%;
    height: 75%;
    border-radius: 30% 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.DivB1{
    background-color: var(--addresscolorvar);
    border-top: 1px solid black;

}

.DivB2{
    background-color: var(--addresscolorvar);
    border-left: 1px solid black;

}


.SkillsDiv {
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.progress {
    background: #ece0ed;
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 40px;
    width: 60%;
    margin: 10px;
    font-size:1.4rem;
}
  
.progress-value {
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #cac3bf;
    height: 30px;
    width: 0;
}

.TDP{
    animation: TDPCss 3s normal forwards;
}
  
@keyframes TDPCss {
    0% { width: 0; }
    100% { width: 100%; }
}

.CppP{
    animation: CppPCss 3s normal forwards;
}
  
@keyframes CppPCss {
    0% { width: 0; }
    100% { width: 90%; }
}

.JsP{
    animation: JsPCss 3s normal forwards;
}
  
@keyframes JsPCss {
    0% { width: 0; }
    100% { width: 90%; }
}

.HtmlP{
    animation: HtmlPCss 3s normal forwards;
}
  
@keyframes HtmlPCss {
    0% { width: 0; }
    100% { width: 90%; }
}

.CssP{
    animation: CssPCss 3s normal forwards;
}
  
@keyframes CssPCss {
    0% { width: 0; }
    100% { width: 80%; }
}

.ReactJsP{
    animation: ReactJsPCss 3s normal forwards;
}
  
@keyframes ReactJsPCss {
    0% { width: 0; }
    100% { width: 99%; }
}

.ReduxP{
    animation: ReduxPCss 3s normal forwards;
}
  
@keyframes ReduxPCss {
    0% { width: 0; }
    100% { width: 80%; }
}

.ReactNativeP{
    animation: ReactNativePCss 3s normal forwards;
}
  
@keyframes ReactNativePCss {
    0% { width: 0; }
    100% { width: 30%; }
}

.NodeJsP{
    animation: NodeJsPCss 3s normal forwards;
}
  
@keyframes NodeJsPCss {
    0% { width: 0; }
    100% { width: 80%; }
}

.MSP{
    animation: MSPCss 3s normal forwards;
}
  
@keyframes MSPCss {
    0% { width: 0; }
    100% { width: 95%; }
}

.SMMP{
    animation: SMMPCss 3s normal forwards;
}
  
@keyframes SMMPCss {
    0% { width: 0; }
    100% { width: 99%; }
}

.GHP{
    animation: GHPCss 3s normal forwards;
}
  
@keyframes GHPCss {
    0% { width: 0; }
    100% { width: 85%; }
}

.BSP{
    animation: BSPCss 3s normal forwards;
}
  
@keyframes BSPCss {
    0% { width: 0; }
    100% { width: 90%; }
}


/* .TDP{
    animation: TDPCss 3s normal forwards;
}
  
@keyframes TDPCss {
    0% { width: 0; }
    100% { width: 68%; }
} */




