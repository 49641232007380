@font-face{
    font-family: "Anton";
    src: url("../../public/Files/Fonts/Anton.ttf");
}

@font-face{
    font-family: "AlfaSlabOne";
    src: url("../../public/Files/Fonts/AlfaSlabOne.ttf");
}

@font-face{
    font-family: "SunsetBoulevard";
    src: url("../../public/Files/Fonts/SunsetBoulevard.ttf");
}



@font-face{
    font-family: "BebasNeue";
    src: url("../../public/Files/Fonts/BebasNeue.ttf");
}

.Cover{
    min-height: 100vh;
    width: 100vw;
    height: 100vh;
}