.CoverPosts{
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color:var(--bgcolorvar);
    display:grid;
    grid-template-rows: repeat(5,20%);
    justify-items: center;
    align-items: center;
}


.PostsText{
    font-family: "AlfaSlabOne";
    background-color: var(--addresscolorvar );
    border: 3px solid var(--bordercolorvar );
    border-radius: 25%;
    font-size: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 80%;
    grid-row: 1/3;

}
.PostsText p {
    margin: 0;
    text-wrap: nowrap;

}



.AddPostButton{
    font-size: 2.5rem;
    padding: 0.35em 1.3em;
    background-color: var(--bgcolorvar);
    border: 8px solid var(--bordercolorvar );
    border-radius: 1em;
    color: #fff;
    font-weight: bolder;
    text-decoration: none;

}

.PostsDiv{
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-row: 4/7;
}


.PostDiv{
    background-color: var(--fourthcolorvar)  !important;
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
    margin: 3%;
    /* width: 40%; */
    /* min-width: 25%; */
    max-width: 27.3%;
    /* max-width: 35%; */
    font-size: 2.7vw;
}

.PostDiv a {
    text-decoration:none ;
}
.PostDiv a p{
    color: rgb(195, 189, 12);
    font-size: 1rem;
}


.PostImage{
    width: 100%;
    padding: 10px;
    width: 39vh;
    height: 39vh;

}

.BlankDiv{
    padding-bottom: 20px;
}


@media screen and (width < 992px) {
    .PostDiv{
        background-color: var(--fourthcolorvar)  !important;
        border: 3px solid var(--bordercolorvar );
        border-radius: 5%;
        margin: 50px;
        min-width: 80%;
        font-size: 3vw;
    }
    
    .PostImage{
        width: 100%;
    }

    .AddPostButton{
        font-size: 2rem;
        padding: 0.3em 1em;
        background-color: var(--bgcolorvar);
        border: 8px solid var(--bordercolorvar );
        border-radius: 1em;
        color: #fff;
        font-weight: bolder;
        text-decoration: none;
    
    }
}


@media screen and (width < 1500px) {
    .PostDiv{
        background-color: var(--fourthcolorvar)  !important;
        border: 3px solid var(--bordercolorvar );
        border-radius: 5%;
        margin: 3%;
        max-width: 44%;
        font-size: 3vw;
    
    }
    

}