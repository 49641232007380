.CoverServices{
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color: var(--bgcolorvar) ;
    display:grid;
    grid-template-rows: repeat(3,33.33%);
    justify-items: center;
    align-items: center;
}

.ServicessText{
    font-family: "AlfaSlabOne";
    background-color: var(--addresscolorvar );
    border: 3px solid var(--bordercolorvar );
    border-radius: 25%;
    font-size: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 80%;
    grid-row: 1/2;

}

.ServicesDiv{
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    grid-row: 2/5;

}
.ServicesDiv p {
    color:bisque;
    font-size: 1.5rem;
    margin:  10px;
}

/* .ServiceDiv{
    background-color: rgba(241, 179, 7, 0.089) !important;
    border: 3px solid rgb(195, 189, 12);
    border-radius: 5%;
    margin: 3%;
    width: 40%;
    min-width: 25%;
    max-width: 27.3%;
    max-width: 35%;
    font-size: 2.7vw;
} */

